import Question from "../models/Question"

const QuestionList = [
  new Question("What is the adult dose of Epinephrine (1:10,000) for cardiac arrest?"),
  new Question("What is the recommended IV dose of Atropine for an adult with symptomatic bradycardia?"),
  new Question("What is the dose of Albuterol for an adult with bronchospasm via nebulizer?"),
  new Question("What is the initial dose of Adenosine for an adult patient with stable supraventricular tachycardia (SVT)?"),
  new Question("What is the initial dose of Amiodarone for a patient in cardiac arrest with ventricular fibrillation?"),
  new Question("What is the dose of Furosemide (Lasix) for an adult with acute pulmonary edema?"),
  new Question("What is the maximum cumulative dose of Atropine for an adult with bradycardia?")

];

export default QuestionList;

