import React, { useState } from 'react';
import Question from '../models/Question';
import QuestionList from '../models/QuestionList';

const QuestionComponent = () => {
  // Initialize the questions state with the initial list of questions
  const [questions, setQuestions] = useState(QuestionList);
  const [newQuestion, setNewQuestion] = useState('');

  // Function to add a new question to the list
  const addQuestion = () => {
    if (newQuestion.trim() === '') {
        alert('Please enter a valid question');
        return;
    }; // Prevent adding empty questions
    const updatedQuestions = [...questions, new Question(newQuestion)];
    setQuestions(updatedQuestions); // Update the state with the new list
    setNewQuestion(''); // Clear the input field
  };

  return (
    <div>
      <ul>
        {questions.map((question, index) => (
          <li key={index}>{question.problem}</li>
        ))}
      </ul>

      {/* Input field and button to add a new question */}
      <input
        type="text"
        value={newQuestion}
        onChange={(e) => setNewQuestion(e.target.value)}
        placeholder="Enter new question"
      />
      <button 
        className="button1" 
        onClick={addQuestion} 
        >
            Add Question
        </button>
    </div>
  );
};

export default QuestionComponent;
