

class Question {

    constructor(problem) {
        this.problem = problem;
        // this.answer = answer;
    }
    
}

export default Question;