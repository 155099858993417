import React from "react";
import { BackToHomeButton } from "../components/NavigationButtons";
import ProtocolsComponent from "../components/ProtocolsComponent";




export default function Protocols() {

    return (
      <div className="App">
        <h1 className="header">Protocols</h1>
          <div>
            <ProtocolsComponent />
            <BackToHomeButton />
          </div>
      </div>
    );
  }
