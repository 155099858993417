import React, { useState } from 'react';
import QuestionList from '../models/QuestionList';

const QuestionListComponent = () => {
  // Initialize the questions state with the initial list of questions
  const [questions] = useState(QuestionList);

  return (
    <div>

      <ul>
        {questions.map((question, index) => (
          <li className="question-list" key={index}>{question.problem}</li>
        ))}
      </ul>
    </div>
  );
};

export default QuestionListComponent;
