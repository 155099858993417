import React from "react";
import { BackToHomeButton } from "../components/NavigationButtons";


export default function DevelopmentPlan() {

    return (
      <div className="App">
        <h1 className="header">Development Plan</h1>
            <div>
                <div className="list-container">
                    <ul className="list">
                        <h2>Goals for the Year</h2>
                        <li>Generate a Daily Question</li>
                        <li>User Profiles</li>
                        <li>Leaderboards</li>
                        <li>Protocol Page Link Questions</li>
                    </ul>
                    <ul className="list">
                        <h2>Future Features</h2>
                        <li>Question Categories Created</li>
                        <li>Admin View of Education Data</li>
                        <li>Department Rankings</li>
                        <li>Drug Dose Study Helper</li>
                    </ul>
                </div>
                <BackToHomeButton />
          </div>
      </div>
    );
  }
