import React from "react";
import { BackToHomeButton } from "../components/NavigationButtons";
import QuestionListComponent from "../components/QuestionListComponent";



export default function QuestionBank() {

    return (
      <div className="App">
        <h1 className="header">Questions</h1>
          <div>
            <QuestionListComponent />
            <BackToHomeButton />
          </div>
      </div>
    );
  }
