import React from "react";
import { BackToHomeButton } from "../components/NavigationButtons";
import QuestionComponent from "../components/QuestionComponent";


export default function Editor() {

    return (
      <div className="App">
        <h1 className="header">Question Editor</h1>
          <div>
            <QuestionComponent />
            <BackToHomeButton />
          </div>
      </div>
    );
  }
