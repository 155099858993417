import React from 'react';

const ProtocolsComponent = () => {
  return (
    <div>
      <iframe
        src={`${process.env.PUBLIC_URL}/Protocols.pdf`}
        width="100%"
        height="650px"
        title="Protocols"
      />
    </div>
  );
};

export default ProtocolsComponent;